import { useCallback, useContext, useMemo, useState, useEffect } from "react";
import {
  Dimmer,
  Loader,
  Image,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Grid,
} from "semantic-ui-react";
import { useReactFlow } from "reactflow";
import { DrawingContext } from "../../../SimulateDrawingTool";
import { CustomButton, InputText, TitleView } from "../../../../../components";
import PackagingMethod from "../../../../packagingSimulation/component/packaging/packagingMachinery";
import Packaging from "../../../../packagingSimulation/component/packaging/pakaging";
import {
  useDeleteAllPackaging,
  useGetPackagingData,
  useGetPackagingTypes,
  useUpdatePackagingDetails,
} from "../../../../../api/processGate/packaging";
import useWindowDimensions from "../../../../../hooks/windowsSize";
import ConfirmModal from "../../../../../components/confirmViewModal/ConfirmModal";
import MainBottomButtonView from "../../../../../components/mainBottomButtonView/MainBottomButtonView";
import { images } from "../../../../../assets/images";
import { useForm } from "react-hook-form";
import { PACKAGING_LEVELS } from "../../../../../config/constants";
import { useGetWasteManagementWasteTypes } from "../../../../../api/static-data";

export const ProcessGatePackagingBase = ({ modalData }: any) => {
  const { packagingId, processGateId, nodeItem } = modalData;

  const { chartEdges, chartNodes, setNodeItem, setChartNodes, saveDrawing } =
    useContext(DrawingContext);
  const { height } = useWindowDimensions();
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);
  const [isSaveBtnClicked, setIsSaveBtnClicked] = useState(false);
  const [clickedPackagingUnit, setClickedPackagingUnit] = useState("baseUnit");
  const { deleteElements } = useReactFlow();

  const [newPackagingData, setNewPackagingData] = useState<
    PackagingData | undefined
  >();

  //get packaging types
  const {
    data: packagingTypeData,
    status: packagingTypeLoad,
    fetchStatus: packagingFetch,
  } = useGetPackagingTypes();

  //get packaging data by id
  const { data, status, fetchStatus, refetch } =
    useGetPackagingData(packagingId);

    const { data: wasteManagements, isLoading: isGetWasteManagementWasteTypes } =
    useGetWasteManagementWasteTypes();

    useEffect(() => {
      setNewPackagingData(data);
    }, [data]);

  const updatePackagingName = (data: any) => {
    const dataOfPackageDetails = {
      data: {
        packagingName: data.packagingName,
      },
      processGateId,
      packagingId,
    };
    mutatePackingDetails(dataOfPackageDetails, {
      onSuccess: (data) => {
        if (packagingId) {
          refetch(packagingId);
        }
        savePackingNodeData(data._id, data.packagingName);
      },
    });
  };

  const { isLoading: isLoadingPackageDetails, mutate: mutatePackingDetails } =
    useUpdatePackagingDetails();

  //delete all packaging data
  const { isLoading, mutate } = useDeleteAllPackaging();

  //get packaging data
  const getPackageData = useCallback(
    (param: any) => {
      return data?.[param] || [];
    },
    [data]
  );

  const updatePackingDetails = (value: any, type: string) => {
    const packageDetails = {
      processGateId,
      packagingId,
      packagingType: type,
      unitSize: data?.unitSize || 0,
      numberOfBaseUnits: data?.numberOfBaseUnits || 0,
      numberOfPacks: data?.numberOfPacks || 0,
      numberOfCases: data?.numberOfCases || 0,
      palletWidth: data?.palletWidth || 0,
      palletLength: data?.palletLength || 0,
    };
    if (type === "numberOfCases") {
      const dataOfPackageDetails = {
        data: {
          ...packageDetails,
          [type]: value?.packageUnitValue,
          palletWidth: value?.palletWidth,
          palletLength: value?.palletLength,
        },
        packagingType: type,
        processGateId,
        packagingId,
      };
      mutatePackingDetails(dataOfPackageDetails, {
        onSuccess: (data) => {
          if (packagingId) {
            refetch(packagingId);
            return;
          }
          savePackingNodeData(data._id);
        },
      });
    } else {
      const dataOfPackageDetails = {
        data: {
          ...packageDetails,
          [type]: value,
        },
        packagingType: type,
        processGateId,
        packagingId,
      };
      mutatePackingDetails(dataOfPackageDetails, {
        onSuccess: (data) => {
          if (packagingId) {
            refetch(packagingId);
            return;
          }
          savePackingNodeData(data._id);
        },
      });
    }
  };

  //save transport node data
  const savePackingNodeData = (id: any, packagingName?: string) => {
    const updatedNode = {
      ...nodeItem,
      data: {
        ...nodeItem?.data,
        ...(packagingName && { label: `Packaging Details (${packagingName})` }),
        reference: {
          packagingId: id,
        },
        description: `Packaging Details`,
      },
    };
    const updatedChartNodes = chartNodes.map((n: any) => {
      if (n.id === nodeItem?.id) {
        return updatedNode;
      }
      return n;
    });
    setChartNodes(updatedChartNodes);
    setNodeItem(updatedNode);
    saveDrawing();
  };

  //delete node data
  const onNodesDelete = () => {
    deleteElements({ nodes: [{ id: nodeItem.id }] });
    setNodeItem(null);
  };

  const values = useMemo(() => {
    return {
      packagingName: data?.packagingName,
    };
  }, [nodeItem, data, processGateId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  //handle loading
  if (
    (status == "loading" && fetchStatus == "fetching") ||
    (packagingTypeLoad == "loading" && packagingFetch == "fetching") ||
    isLoading ||
    isLoadingPackageDetails
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <div>
      <div
        style={{
          height: height * 0.9 - 100,
          overflowX: "hidden",
        }}
      >
        <Accordion>
          <AccordionTitle
            active={activeIndex === 1}
            index={1}
            onClick={() => {
              setActiveIndex(activeIndex === 1 ? 0 : 1);
            }}
          >
            <TitleView title="Please add information about how you package products at each level following the GS1 guidelines. Use this image as a reference." />
          </AccordionTitle>
          <AccordionContent active={activeIndex === 1}>
            <Image src={images.PackingProcess} />
          </AccordionContent>
        </Accordion>
        <TitleView title="Packaging Details" />
        <form onSubmit={handleSubmit(updatePackagingName)}>
        <Grid>
            <Grid.Column style={{ marginBottom: 10 }}>
              <label className="textLabel">Packaging Name</label>
              <br />
              <label className="textLabel">{data?.packagingName}</label>
            </Grid.Column>
          </Grid>
        </form>
        <Packaging
          packagingData={data}
          packagingType="base-unit"
          newPackagingData={newPackagingData}
          setNewPackagingData={setNewPackagingData}
          packagingMaterials={packagingTypeData}
          isView={false}
        />
        <Packaging
          packagingData={data}
          packagingType="sales-unit"
          newPackagingData={newPackagingData}
          setNewPackagingData={setNewPackagingData}
          packagingMaterials={packagingTypeData}
          isView={false}
        />
        <Packaging
          packagingData={data}
          packagingType="bulk-unit"
          newPackagingData={newPackagingData}
          setNewPackagingData={setNewPackagingData}
          packagingMaterials={packagingTypeData}
          isView={false}
        />
        <Packaging
          packagingData={data}
          packagingType="transport-unit"
          newPackagingData={newPackagingData}
          setNewPackagingData={setNewPackagingData}
          packagingMaterials={packagingTypeData}
          isView={false}
        />
        <PackagingMethod
          title="Packaging Machinery"
          packagingData={data}
          newPackagingData={newPackagingData}
          setNewPackagingData={setNewPackagingData}
          packagingLevels={PACKAGING_LEVELS}
          wasteManagements={wasteManagements}
          isView={false}
        />
      </div>
      <MainBottomButtonView
        cancelStatus={true}
        deleteStatus={packagingId}
        saveButtonStatus={true}
        deleteButton={() => setVisibleDeleteModal(true)}
        cancelButton={() => setNodeItem(null)}
        isSimulation={false}
      />
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          setVisibleDeleteModal(false);
        }}
        approve={() => {
          const dataToDelete = {
            packagingId,
            processGateId,
          };
          mutate(dataToDelete, {
            onSuccess(data) {
              onNodesDelete();
            },
          });
          setVisibleDeleteModal(false);
        }}
        title={`Delete Process gate packaging`}
        subTitle="Are you sure you want to remove the Process gate packaging? Please consider you cannot recover these data after remove"
      />
    </div>
  );
};
